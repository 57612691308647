<template>
  <div class="row">
    <div class="col-md-12 grid-margin">
      <div class="row">
        <div class="col-12 col-xl-8 mb-4 mb-xl-0">
          <h3 class="font-weight-bold white">Bem vindo ao Elementista</h3>
          <h6 class="font-weight-normal mb-0 white">Nossa missão é insprar <span class="orange">apróxima geração</span>
          </h6>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card position-relative">
        <div class="card-body">
          <div id="detailedReports" class="carousel slide detailed-report-carousel position-static pt-2"
            data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <p class="card-title">Feed</p>
                <div class="row">
                  <div class="col-md-3">
                    <p>O projeto que ensina robótica para crianças!</p>
                    <img style="max-width: 100%; max-height: 270px;" :src="img" />
                  </div>
                  <div class="col-md-3 border-right">
                    <p>Robótica para crianças, porque é uma boa opção?</p>
                    <img style="max-width: 100%; max-height: 270px;" :src="img1" />
                  </div>
                  <div class="col-md-3">
                    <p>Tecnologia e desenvolvimento infantil</p>
                    <img style="max-width: 100%; max-height: 270px;" :src="img" />
                  </div>
                  <div class="col-md-3 ">
                    <p>Qual a importância da robótica na aprendizagem escolar?</p>
                    <img style="max-width: 100%; max-height: 270px;" :src="img2" />
                  </div>

                </div>

              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-6 ">
                    <div class="card">
                      <div class="card-body">
                        <p class="card-title">Notícias</p>
                        <p class="font-1 font-weight-500  text-primary">Estudantes participam de 1º Campeonato de
                          robótica da educação infantil em Boa Vista</p>
                        <p>
                          O campeonato
                          Assim, o campeonato é parte das atividades que as escolas tem que desenvolver com o estudantes
                          porque ganharam da empresa Positivo (Programa FIRST LEGO League Discover) com kits de Lego
                          entregues esse ano.
                          Do mesmo modo, a seleção, feita nacionalmente em 2021, contemplou seis escolas da região
                          Norte, cinco delas são de Boa Vista.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-xl-6">
                    <div class="row">

                      <div class="col-md-12 mt-3">
                        <p class="card-title">Descobrindo a robótica na pré-escola</p>
                        <img class="mb-2 mb-xl-0" style="max-width: 390px; max-height: 270px;" :src="img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#detailedReports" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#detailedReports" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card position-relative">
        <div class="card-body">
          <div id="detailedReports2" class="carousel slide detailed-report-carousel position-static pt-2"
            data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <p class="col-md-1 card-title">Post</p>
                <div class="row">
                  <div class="col-md-12 col-xl-9">
                    <div class="row">
                      <div class="col-md-6 border-right">
                        <div class="card">
                          <div class="card-body">
                            <p class="card-title">O campeonato
                              Assim</p>
                            <p class="font-weight-500"> O campeonato é parte das atividades que as escolas tem que
                              desenvolver com o
                              estudantes porque ganharam da empresa Positivo (Programa FIRST LEGO League Discover) com
                              kits de Lego entregues esse ano.

                              Do mesmo modo, a seleção, feita nacionalmente em 2021, contemplou seis escolas da região
                              Norte, cinco delas são de Boa Vista.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 mt-1">
                        <div class="card">
                          <div class="card-body">
                            <p class="card-title">Robótica para crianças</p>
                            <p class="font-weight-500">A robótica para crianças é uma ferramenta educacional que prepara
                              os jovens para um futuro cada vez mais tecnológico.

                              Introduzir as crianças à robótica significa muito mais do que simplesmente ensiná-las a
                              construir robôs; trata-se de desenvolver habilidades fundamentais como raciocínio lógico,
                              resolução de problemas, criatividade e trabalho em equipe. </p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <p class="col-md-1 card-title">Post</p>
                <div class="row">
                  <div class="row">
                    <div class="col-md-12 col-xl-9">
                      <div class="row">
                        <div class="col-md-6 border-right">
                          <div class="card">
                            <div class="card-body">
                              <p class="card-title">Crianças engajadas aprendem mais</p>
                              <p class="font-weight-500">Com o uso de robôs na educação e sem o uso de telas, nós
                                professores podemos fazer atividades apropriadas a cada faixa etária e que promovam o
                                aprendizado e o desenvolvimento infantil. Um experimento acompanhou uma escola em um
                                conjunto habitacional de baixa renda em Hong Kong, na China, para entender as
                                possibilidades e os desafios da programação de robôs na educação infantil. </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 mt-1">
                          <div class="card">
                            <div class="card-body">
                              <p class="card-title">Lego e robótica? Sim, são crianças aprendendo tecnologia e a arte de
                                brincars</p>
                              <p class="font-weight-500">Quem disse que brincar não ensina? Aprender nem sempre está
                                condicionado a uma sala de aula tradicional. É uma arte que, quando bem trabalhada,
                                conecta a criança ao mundo do conhecimento e aguça suas curiosidades. Dar asas à
                                imaginação tem se tornado um lema durante as oficinas oferecidas pela Associação
                                Adventista de Desenvolvimento e Recursos Assistenciais (ADRA) às famílias em situação de
                                vulnerabilidade social do bairro do Oásis.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#detailedReports2" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#detailedReports2" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-md-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <p class="card-title">Order Details</p>
          <p class="font-weight-500">The total number of sessions within the date range. It is the period time a user is
            actively engaged with your website, page or app, etc</p>

        </div>
      </div>
    </div>
    <div class="col-md-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Sales Report</p>
            <a href="#" class="text-info">View all</a>
          </div>
          <p class="font-weight-500">The total number of sessions within the date range. It is the period time a user is
            actively engaged with your website, page or app, etc</p>
          <div id="sales-legend" class="chartjs-legend mt-4 mb-2"></div>

        </div>
      </div>
    </div>
    <div class="col-md-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Sales Report</p>
            <a href="#" class="text-info">View all</a>
          </div>
          <p class="font-weight-500">The total number of sessions within the date range. It is the period time a user is
            actively engaged with your website, page or app, etc</p>
          <div id="sales-legend" class="chartjs-legend mt-4 mb-2"></div>

        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <p class="card-title">Order Details</p>
          <p class="font-weight-500">The total number of sessions within the date range. It is the period time a user is
            actively engaged with your website, page or app, etc</p>

        </div>
      </div>
    </div>
    <div class="col-md-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Sales Report</p>
            <a href="#" class="text-info">View all</a>
          </div>
          <p class="font-weight-500">The total number of sessions within the date range. It is the period time a user is
            actively engaged with your website, page or app, etc</p>
          <div id="sales-legend" class="chartjs-legend mt-4 mb-2"></div>

        </div>
      </div>
    </div>
    <div class="col-md-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Sales Report</p>
            <a href="#" class="text-info">View all</a>
          </div>
          <p class="font-weight-500">The total number of sessions within the date range. It is the period time a user is
            actively engaged with your website, page or app, etc</p>
          <div id="sales-legend" class="chartjs-legend mt-4 mb-2"></div>

        </div>
      </div>
    </div>
  </div> -->

</template>

<script>

import FeedImagem1 from "../../public/imgFeed/modelo1.jpg";
import FeedImagem2 from "../../public/imgFeed/modelo2.jpg";
import FeedImagem3 from "../../public/imgFeed/modelo3.jpg";

export default {
  name: 'HelloWorld',
  data() {
    return {
      img: FeedImagem1,
      img1: FeedImagem2,
      img2: FeedImagem3
    }

  }

}
</script>

<style scoped>
.font-1 {
  font-size: 20px;
  line-height: 1.6;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
